<template>
    <div class="brand">
        <img src="/img/brand/streamforge-logo-text.png">
    </div>

    <div class="error-wrapper" v-if="error">
        <div class="error-inner m-auto text-center">
            <h1 class="display-header">Invalid Link</h1>
            <p class="lead">The link you were given isn't quite right...</p>
        </div>
    </div>

    <div class="loading-wrapper" v-else>
        <div class="spinner-wrapper m-auto">
            <div class="board">
                <div class="left"></div>
                <div class="right"></div>
                <div class="ball"></div>
                <div class="ballhit"></div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import device from 'current-device'
import { defineComponent } from 'vue'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

export default defineComponent({
    name: 'clickthrough',

    data() {
        return {
            error: false,
        }
    },

    created() {
        const urlTokens = window.location.pathname.substring(1).split('-')

        if (urlTokens.length !== 3) {
            console.error('Malformed clickthrough link.')
            this.error = true
            return
        }

        const platformMap: { [key: string]: string } = {
            tw: 'twitch',
            yt: 'youtube',
            tt: 'twitter',
            ig: 'instagram',
            tk: 'tiktok',
            fb: 'facebook',
        }

        console.log(urlTokens)

        const campaignAbbreviation = urlTokens[0]
        const platformId = platformMap[urlTokens[1]]
        const platformUserName = urlTokens[2]

        if (!platformId) {
            console.error('Invalid platform ID provided.')
            this.error = true
            return
        }

        const requestConfig: AxiosRequestConfig = {
            method: 'post',
            baseURL: process.env.VUE_APP_API_BASE_URL,
            url: '/clickthrough',
            data: {
                campaign_abbreviation: campaignAbbreviation,
                platform_user_name: platformUserName,
                platform_id: platformId,
                referrer: document.referrer ? document.referrer : null,
                userAgent: navigator.userAgent,
                language: navigator.language,
                screenDimensions: {
                    screen_height: window.screen.height,
                    screen_width: window.screen.width,
                    window_height: window.innerHeight,
                    window_width: window.innerWidth,
                },
                deviceType: device.type,
                operatingSystem: device.os,
            },
        }

        axios(requestConfig).then((response: AxiosResponse) => {
            if (response.data.redirectURL) {
                window.location.replace(response.data.redirectURL)
            }
        }).catch((error: Error) => {
            console.log('Error getting redirect url', error)
            this.error = true
        })
    },
})
</script>

<style lang="scss">
* {
    box-sizing: border-box;
}

html, body, #app {
    font-family: Inter, sans-serif;
    font-size: 1rem;
    line-height: 1.5;
    color: #bec2cc;
    background-color: #12141d;
    height: 100%;
    width: 100%;
    margin: 0;
}

.brand {
    position: absolute;
    top: 0;
    left: 0;
    padding: 25px;

    img {
        height: 50px;
    }
}

.display-header {
    font-weight: 600;
    line-height: 1.3;
    font-size: 3.3rem;
    margin: 0;
    color: #fff;
}

.lead {
    font-size: 1.25rem;
    margin: 0;
    font-weight: 300;
    line-height: 1.7;
}

.error-wrapper, .loading-wrapper {
    height: 100%;
    display: flex;
    text-align: center;

    .error-inner, .spinner-wrapper {
        margin: auto;
    }
}

.board {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 250px;

    .left,
    .right {
        height: 50px;
        width: 8px;
        background: white;
        display: inline-block;
        position: absolute;
    }

    .left {
        left: 0;
        animation: position1 2s linear infinite;
    }
    .right {
        right: 0;
        animation: position2 2s linear infinite;
    }

    .ball,
    .ballhit {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: white;
        position: absolute;
        animation: pongBounce 2s linear infinite;
    }
    .ballhit {
        padding: 11px;
        margin: -6px 0 0 -6px;
        border-radius: 50%;
        background: #12141d;
        border: 2px #fff solid;
        z-index: -1;
        animation: pongBounce2 2s linear infinite;
    }
}

@keyframes position1 {
    0% {
        top: -60px;
    }
    25% {
        top: 0;
    }
    50% {
        top: 60px;
    }
    75% {
        top: -60px;
    }
    100% {
        top: -60px;
    }
}

@keyframes position2 {
    0% {
        top: 60px;
    }
    25% {
        top: 0;
    }
    50% {
        top: -60px;
    }
    75% {
        top: -60px;
    }
    100% {
        top: 60px;
    }
}

@keyframes pongBounce {
    0% {
        top: -35px;
        left: 10px;
    }
    25% {
        top: 25px;
        left: 225px;
    }
    50% {
        top: 75px;
        left: 10px;
    }
    75% {
        top: -35px;
        left: 225px;
    }
    100% {
        top: -35px;
        left: 10px;
    }
}

@keyframes pongBounce2 {
    0% {
        top: -35px;
        left: 10px;
        border: 2px #12141d solid;
    }
    24% {
        border: 2px #12141d solid;
    }
    25% {
        top: 25px;
        left: 225px;
        border: 2px #fff solid;
    }
    49% {
        border: 2px #12141d solid;
    }
    50% {
        top: 75px;
        left: 10px;
        border: 2px #fff solid;
    }
    74% {
        border: 2px #12141d solid;
    }
    75% {
        top: -35px;
        left: 225px;
        border: 2px #fff solid;
    }
    99% {
        border: 2px #12141d solid;
    }
    100% {
        top: -35px;
        left: 10px;
        border: 2px #fff solid;
    }
}
</style>
