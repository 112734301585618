
import device from 'current-device'
import { defineComponent } from 'vue'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

export default defineComponent({
    name: 'clickthrough',

    data() {
        return {
            error: false,
        }
    },

    created() {
        const urlTokens = window.location.pathname.substring(1).split('-')

        if (urlTokens.length !== 3) {
            console.error('Malformed clickthrough link.')
            this.error = true
            return
        }

        const platformMap: { [key: string]: string } = {
            tw: 'twitch',
            yt: 'youtube',
            tt: 'twitter',
            ig: 'instagram',
            tk: 'tiktok',
            fb: 'facebook',
        }

        console.log(urlTokens)

        const campaignAbbreviation = urlTokens[0]
        const platformId = platformMap[urlTokens[1]]
        const platformUserName = urlTokens[2]

        if (!platformId) {
            console.error('Invalid platform ID provided.')
            this.error = true
            return
        }

        const requestConfig: AxiosRequestConfig = {
            method: 'post',
            baseURL: process.env.VUE_APP_API_BASE_URL,
            url: '/clickthrough',
            data: {
                campaign_abbreviation: campaignAbbreviation,
                platform_user_name: platformUserName,
                platform_id: platformId,
                referrer: document.referrer ? document.referrer : null,
                userAgent: navigator.userAgent,
                language: navigator.language,
                screenDimensions: {
                    screen_height: window.screen.height,
                    screen_width: window.screen.width,
                    window_height: window.innerHeight,
                    window_width: window.innerWidth,
                },
                deviceType: device.type,
                operatingSystem: device.os,
            },
        }

        axios(requestConfig).then((response: AxiosResponse) => {
            if (response.data.redirectURL) {
                window.location.replace(response.data.redirectURL)
            }
        }).catch((error: Error) => {
            console.log('Error getting redirect url', error)
            this.error = true
        })
    },
})
